
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.NotFound {
  margin-top: spacing(4);
  margin-bottom: spacing(4);
  text-align: center;
}

.Title {
  margin-bottom: spacing(3);
}

.Animation {
  max-width: 700px;
  margin: 0 auto;
}